import {
  RESOURCE_ADDRESS_TO_STAKE,
  STAKED_TOKEN_ADDRESS,
} from "../Constants/addresses";
import { gatewayApi } from "../Constants/endpoints";

export const getAccountBalance = async (address) => {
  let balance = 0;
  let nftarray;

  const result = await gatewayApi.state.getEntityDetailsVaultAggregated(
    address
  );
  let resource = result.fungible_resources.items.find(
    (it) => it.resource_address === RESOURCE_ADDRESS_TO_STAKE
  );
  let nftresource = result.non_fungible_resources.items.find(
    (it) => it.resource_address === STAKED_TOKEN_ADDRESS
  );
  if (resource && resource.vaults.items.length) {
    balance = +resource.vaults.items[0].amount;
  }
  if (nftresource && nftresource.vaults.items.length) {
    nftarray = nftresource.vaults.items[0].items;
  }
  return [balance, nftarray];
};

export const getNFTData = async (nftaddress, indexArray) => {
  const url = "https://mainnet.radixdlt.com/state/non-fungible/data";

  const payload = {
    resource_address: nftaddress,
    non_fungible_ids: indexArray,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    // console.log(data);

    // console.log(data.non_fungible_ids[0].data.programmatic_json.fields);
    // return data.non_fungible_ids[0].data.programmatic_json.fields;
    return data.non_fungible_ids;
  } catch (error) {
    console.error(error);
  }
};

export const getTokenData = async (address) => {
  const url = "https://mainnet.radixdlt.com/state/entity/details";

  const payload = {
    addresses: [`${address}`],
    aggregation_level: "Vault",
    opt_ins: {
      ancestor_identities: true,
      component_royalty_vault_balance: true,
      package_royalty_vault_balance: true,
      non_fungible_include_nfids: true,
      explicit_metadata: ["name", "symbol", "description"],
    },
  };
  // console.log(payload);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    // console.log(data.items[0].fungible_resources.items);
    // console.log(data.non_fungible_ids[0].data.programmatic_json.fields);
    // return data.non_fungible_ids[0].data.programmatic_json.fields;
    return data.items[0].fungible_resources.items;
  } catch (error) {
    console.error(error);
  }
};
