import TokenCacheService from "../Classes/tokenCache";
import { TOKENLIST_EXPIRE_PERIOD } from "../Constants/misc";

export const countUserStakedFOMO = (nftInformations) => {
  let count = 0;
  nftInformations.forEach((nft) => {
    count = count + +nft.data.programmatic_json.fields[2].value;
  });
  return count;
};

export const chunkArray = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
    arr.slice(index * size, index * size + size)
  );

export const fetch_KeyValueStoreData_in_chunks = async (
  keyValureStoreAddress,
  list,
  gatewayApi,
  ledgerState,
  chunkSize = 100
) => {
  const usersChunks = chunkArray(list, chunkSize);

  const chunkPromises = usersChunks.map((usersChunk) =>
    gatewayApi.state.innerClient.keyValueStoreData({
      stateKeyValueStoreDataRequest: {
        key_value_store_address: keyValureStoreAddress,
        keys: usersChunk,
        at_ledger_state: ledgerState,
      },
    })
  );

  const chunkData = (await Promise.all(chunkPromises)).flat();

  return chunkData;
};

export function isObjectEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false; // Object is not empty
    }
  }
  return true; // Object is empty
}

export const shouldFetchTokenList = async (lastTokensUpdated) => {
  const currentTime = Date.now();
  const tokensListIsEmpty = isObjectEmpty(TokenCacheService.allTokens);
  // Check if the current time is greater than or equal to 24 hours after lastTokensUpdated
  // or if tokens does not exist in redux
  return (
    currentTime - lastTokensUpdated >= TOKENLIST_EXPIRE_PERIOD ||
    tokensListIsEmpty
  );
};
