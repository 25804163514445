import { createSlice } from "@reduxjs/toolkit";
import { countUserStakedFOMO } from "../../Utils/helpers";

const initialState = {
  successTx: 0,
  userFomoNfts: [],
  userTokens: [],
  fomoBalance: 0,
  userStakedFomo: 0,
  latestAirdrops: [],
};

const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    incrementSuccessTx(state) {
      state.successTx = state.successTx + 1;
    },
    setUserFomoNfts(state, { payload }) {
      state.userFomoNfts = payload;
      state.userStakedFomo = countUserStakedFOMO(payload);
    },
    setUserTokens(state, { payload }) {
      state.userTokens = payload;
    },
    setFomoBalance(state, { payload }) {
      state.fomoBalance = payload;
    },
    setUserStakedFomo(state, { payload }) {
      state.userStakedFomo = payload;
    },
    setLatestAirdrops(state, { payload }) {
      state.latestAirdrops = payload;
    },
  },
});

export default session.reducer;

export const {
  incrementSuccessTx,
  setUserFomoNfts,
  setUserTokens,
  setFomoBalance,
  setUserStakedFomo,
  setLatestAirdrops,
} = session.actions;
