import { GatewayApiClient } from "@radixdlt/babylon-gateway-api-sdk";
import { store } from "../Store";
import { incrementSuccessTx } from "../Store/Reducers/session";
import {
  RESOURCE_ADDRESS_TO_STAKE,
  STAKED_TOKEN_ADDRESS,
  STAKE_COMPONENT_ADDRESS,
} from "../Constants/addresses";
import { getRdt } from "../subs";
import { generateRandomNonce } from "@radixdlt/radix-engine-toolkit";

export const Stake = async (inputStakingAmount, accountAddress) => {
  const rdt = getRdt();
  try {
    if (inputStakingAmount > 0) {
      const stake_manefist = `
        CALL_METHOD
          Address("${accountAddress}")
          "withdraw"
          Address("${RESOURCE_ADDRESS_TO_STAKE}")
          Decimal("${inputStakingAmount}")
        ;
        
        TAKE_ALL_FROM_WORKTOP
          Address("${RESOURCE_ADDRESS_TO_STAKE}")
          Bucket("tokens")
        ;
        
        CALL_METHOD
          Address("${STAKE_COMPONENT_ADDRESS}")
          "add_stake"
          Bucket("tokens")
        ;
        
        CALL_METHOD
          Address("${accountAddress}")
          "deposit_batch"
          Expression("ENTIRE_WORKTOP")
      ;`;
      console.log("-------Stake-------", stake_manefist);
      const result = await rdt.walletApi.sendTransaction({
        transactionManifest: stake_manefist,
      });
      if (result.isErr()) {
        throw result.error;
      }
      console.log("stakingresult", result);
      const transactionIntentHash = result.transactionIntentHash;
      console.log("stakingtransactionIntentHash", transactionIntentHash);
      store.dispatch(incrementSuccessTx());
    } else {
      alert("Input Amount To Stake");
    }
  } catch (error) {
    console.log("Unable to send Transaction", error);
  }
};

export const unStake = async (selectedNftID, accountAddress) => {
  const rdt = getRdt();
  try {
    if (selectedNftID.length > 0) {
      const unstake_manefist = `
          CALL_METHOD
            Address("${accountAddress}")
            "withdraw_non_fungibles"
            Address("${STAKED_TOKEN_ADDRESS}")
            Array<NonFungibleLocalId>(NonFungibleLocalId("${selectedNftID}"))
          ;
          
          TAKE_ALL_FROM_WORKTOP
            Address("${STAKED_TOKEN_ADDRESS}")
            Bucket("tokens")
          ;
          
          CALL_METHOD
            Address("${STAKE_COMPONENT_ADDRESS}")
            "remove_stake"
            Bucket("tokens")
          ;
        
          CALL_METHOD
            Address("${accountAddress}")
            "deposit_batch"
            Expression("ENTIRE_WORKTOP")
        ;`;
      console.log("-------unStake-------", unstake_manefist);
      const result = await rdt.walletApi.sendTransaction({
        transactionManifest: unstake_manefist,
      });
      if (result.isErr()) {
        throw result.error;
      }
      console.log("result", result);
      const transactionIntentHash = result.value.transactionIntentHash;
      console.log("transactionIntentHash", transactionIntentHash);
      store.dispatch(incrementSuccessTx());
    } else {
      alert("Select NFT To Unstake");
    }
  } catch (error) {
    console.log("Unable to send Transaction", error);
  }
};

export const airdrop = async (
  inputAirdropAmount,
  selectedTokenIDToAirdrop,
  accountAddress
) => {
  const rdt = getRdt();
  try {
    if (inputAirdropAmount > 0 && selectedTokenIDToAirdrop.length > 0) {
      const airdrop_manefist = `
            
        CALL_METHOD
          Address("${accountAddress}")
          "withdraw"
          Address("${selectedTokenIDToAirdrop}")
          Decimal("${inputAirdropAmount}")
        ;
        
        TAKE_ALL_FROM_WORKTOP
          Address("${selectedTokenIDToAirdrop}")
          Bucket("tokens")
        ;
        
        CALL_METHOD
          Address("${STAKE_COMPONENT_ADDRESS}")
          "airdrop"
          Bucket("tokens")
        ;`;

      console.log("-------Stake-------", airdrop_manefist);
      const result = await rdt.walletApi.sendTransaction({
        transactionManifest: airdrop_manefist,
      });
      if (result.isErr()) {
        throw result.error;
      }
      console.log("stakingresult", result);
      const transactionIntentHash = result.value.transactionIntentHash;
      console.log("stakingtransactionIntentHash", transactionIntentHash);
      store.dispatch(incrementSuccessTx());
    } else {
      alert("Select a Token and Input Amount To Airdrop");
    }
  } catch (error) {
    console.log("Unable to send Transaction", error);
  }
};

export const simulateUnstakeTx = async () => {
  const gatewayApi = GatewayApiClient.initialize({
    basePath: "https://mainnet.radixdlt.com",
  });

  const manifest =
    'CALL_METHOD Address("account_rdx16xl8ugf4hzw92c9q2glremneep9jp8lqmcpctz5e5ggylcaunn989a") "withdraw_non_fungibles" Address("resource_rdx1nf4djwvfsel2axsggh6k6x8a2cuycayn08c97z5u34uuegtdan5d7z") Array<NonFungibleLocalId>(NonFungibleLocalId("#450#")) ; TAKE_ALL_FROM_WORKTOP Address("resource_rdx1nf4djwvfsel2axsggh6k6x8a2cuycayn08c97z5u34uuegtdan5d7z") Bucket("tokens") ; CALL_METHOD Address("component_rdx1czhz5y9eucherc7gvwhsjvdu6yeywh9pdtrulu5n76mt0x8llf536e") "remove_stake" Bucket("tokens") ; CALL_METHOD Address("account_rdx16xl8ugf4hzw92c9q2glremneep9jp8lqmcpctz5e5ggylcaunn989a") "deposit_batch" Expression("ENTIRE_WORKTOP");';

  const latestLedgerState =
    await gatewayApi.transaction.innerClient.transactionConstruction();

  const nonce = await generateRandomNonce();

  const preview = await gatewayApi.transaction.innerClient.transactionPreview({
    transactionPreviewRequest: {
      manifest,
      nonce,
      tip_percentage: 0,
      flags: {
        use_free_credit: true,
        assume_all_signature_proofs: true,
        skip_epoch_check: true,
      },
      start_epoch_inclusive: latestLedgerState.ledger_state.epoch,
      end_epoch_exclusive: latestLedgerState.ledger_state.epoch + 1,
      signer_public_keys: [],
    },
  });

  console.log("preview", preview);
};
