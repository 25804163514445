import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { MagnifyingGlass } from "react-loader-spinner";
import { useParams } from "react-router-dom";

import { HIT_SERVER_URL } from "../../Constants/endpoints";
import TokenShareTile from "./Components/tokenShareTile";
import { useSelector } from "../../Store";

const NFTData = () => {
  const { id } = useParams();
  const [typedNftId, setTypedNftId] = useState(id || "");
  const [nftData, setNftData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNftID, setSelectedNftID] = useState("");

  const userFomoNfts = useSelector((state) => state.session.userFomoNfts);

  useEffect(() => {
    if (id) {
      searchNFT(id);
    }
  }, [id]);

  const searchNFT = async (urlId) => {
    const idToSearch =
      typedNftId !== ""
        ? typedNftId
        : selectedNftID !== ""
        ? selectedNftID
        : urlId;
    if (!isLoading && idToSearch) {
      try {
        setIsLoading(true);
        const { data, status } = await axios.post(
          `${HIT_SERVER_URL}/fomo/get-fomo-data`,
          { id: idToSearch }
        );

        if (status === 201 && Object.values(data).length > 0) {
          setIsError(false);
          setNftData(Object.values(data));
        } else {
          setIsError(true);
          setNftData([]);
        }
      } catch (error) {
        setIsError(true);
        setNftData([]);
      }
      setIsLoading(false);
    }
  };

  const renderNftData = useCallback(() => {
    return nftData.map((data) => (
      <TokenShareTile tokenData={data} key={data.resource_address} />
    ));
  }, [nftData]);

  const handleSelectChange = (event) => {
    setSelectedNftID(event.target.value);
    setTypedNftId("");
  };

  const handleSearchChange = (e) => {
    setTypedNftId(e.target.value);
    if (selectedNftID !== "") {
      setSelectedNftID("");
    }
  };

  return (
    <div
      style={{ height: "calc(100vh - 125px)" }}
      className="pt-[50px] flex flex-col items-center"
    >
      <div className="flex-1 min-h-[100%] flex flex-col items-center">
        {userFomoNfts.length > 0 && (
          <>
            <div className="flex text-left">
              <div>
                <select
                  className="bg-[#232325] bg-opacity-60 p-[10px]  outline-none w-full rounded-lg text-white"
                  value={selectedNftID}
                  onChange={handleSelectChange}
                >
                  <option value="" disabled selected hidden>
                    Select your FOMO NFT
                  </option>
                  {userFomoNfts.map((option) => (
                    <option
                      key={option.non_fungible_id}
                      value={+option.non_fungible_id.replace(/#/g, "")}
                    >
                      {option.non_fungible_id} :{" "}
                      {option.data.programmatic_json.fields[2].value} FOMO
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <p className="font-semibold text-white my-2">OR</p>
          </>
        )}
        <div className="flex items-center flex-col justify-center w-full">
          <input
            placeholder="Search by NFT id#"
            type="number"
            value={typedNftId}
            className="rounded-lg w-full bg-[#232325] bg-opacity-60 text-white placeholder:text-white "
            onChange={handleSearchChange}
          />
          <div
            onClick={searchNFT}
            className="w-full bg-green-500 p-[10px] text-center my-[5px] rounded-lg font-bold hover:cursor-pointer hover:bg-opacity-80"
          >
            Search NFT Rewards
          </div>
        </div>
        <div
          className={"flex items-center flex-1 flex-col w-[90vw] max-w-[350px] gap-3 overflow-auto ".concat(
            isError || isLoading ? "justify-center" : "justify-start py-3"
          )}
        >
          {isLoading ? (
            <div className="self-center">
              <MagnifyingGlass
                visible={true}
                height="80"
                width="80"
                ariaLabel="magnifying-glass-loading"
                wrapperStyle={{}}
                wrapperClass="magnifying-glass-wrapper"
                glassColor="#c0efff"
                color="#0d9f6e"
              />
              <p className="text-white">Searching...</p>
            </div>
          ) : nftData.length > 0 ? (
            renderNftData()
          ) : isError ? (
            <div className="self-center">
              <p className="text-white">No NFT data found :(</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NFTData;
