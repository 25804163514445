import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "flowbite-react";

import { useSelector } from "../../Store";
import { Stake, airdrop, unStake } from "../../Utils/txSenders";
import { formatTokenAmount } from "../../Utils/formatter";
import AirdropConfirmModal from "../../Components/Modals/airdropConfirm";

export const StakingPage = () => {
  const navigate = useNavigate();

  const [inputStakingAmount, setInputAmount] = useState(0);
  const [selectedNftID, setSelectedNftID] = useState("");
  const [selectedTokenIDToAirdrop, setSelectedTokenIDToAirdrop] = useState("");
  const [inputAirdropAmount, setInputAirdropAmount] = useState(0);
  const [isConfirmAirdropOpen, setIsConfirmAirdropOpen] = useState(false);

  const walletAddress = useSelector((state) => state.app.walletAddress);
  const userFomoNfts = useSelector((state) => state.session.userFomoNfts);
  const userTokens = useSelector((state) => state.session.userTokens);
  const userStakedFomo = useSelector((state) => state.session.userStakedFomo);
  const fomoBalance = useSelector((state) => state.session.fomoBalance);

  const handleSelectChange = (event) => {
    setSelectedNftID(event.target.value);
  };
  const handleSelectToken = (event) => {
    setSelectedTokenIDToAirdrop(event.target.value);
  };
  const setMaxStakingAmount = () => {
    setInputAmount(fomoBalance);
  };

  const searchNftData = () => {
    navigate("/nft");
  };

  return (
    <>
      <div className=" w-full pt-[110px] lg:flex  text-white bg-[url('../public/images/back1.png')] bg-opacity-0 bg-cover bg-center">
        <img
          src="images/blur-ai.png"
          className="absolute hidden lg:block"
          alt="blur-ai"
        />
        <div className="mx-auto">
          <div className=" bg-[#232325] bg-opacity-10 backdrop-blur-lg lg:min-w-[500px] min-w-[320px] px-[20px] justify-center pt-[40px] pb-1 rounded-lg relative border-[1px] border-gray-600">
            <div className="flex relative"></div>
            <div className="text-[25px] text-green-500 font-bold py-[20px] text-center ">
              AIRDROPS
            </div>

            <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] py-3 mt-[5px] border-l-2 border-green-500 rounded-t-lg">
              <div className="flex text-left">
                <div className="w-2/3">
                  <select
                    className="bg-[#232325] bg-opacity-60 p-[10px]  outline-none w-1/2 rounded-l-lg"
                    value={[selectedTokenIDToAirdrop]}
                    onChange={handleSelectToken}
                  >
                    <option value="">Select a Token </option>
                    {userTokens.map(
                      (option) =>
                        option.explicit_metadata.items.length > 2 && (
                          <option
                            key={option.resource_address}
                            value={[
                              option.resource_address,
                              option.explicit_metadata.items[1].value.typed
                                .value,
                            ]}
                          >
                            {
                              option.explicit_metadata.items[1].value.typed
                                .value
                            }{" "}
                            : {option.vaults.items[0].amount}
                          </option>
                        )
                    )}
                  </select>
                  <input
                    type="number"
                    placeholder="Input Amount"
                    min={1}
                    value={inputAirdropAmount}
                    onChange={(e) =>
                      setInputAirdropAmount(parseInt(e.target.value))
                    }
                    className="bg-[#232325] bg-opacity-60 p-[10px] outline-none w-1/2 rounded-l-lg border-solid border-[1px] border-green-500"
                  ></input>
                </div>
                <div className="w-1/3">
                  <Tooltip
                    content="Airdrop tokens to stakers"
                    theme={{ target: "w-full" }}
                    className="bg-[#232325] bg-opacity-90 backdrop-blur-lg"
                    arrow={false}
                  >
                    <div
                      // onClick={() => setIsConfirmAirdropOpen(true)}
                      className=" bg-green-500 p-[10px] w-full  text-center font-bold rounded-r-lg border-solid border-[1px] border-green-500 hover:cursor-pointer hover:bg-opacity-80"
                    >
                      AIRDROP
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className=" bg-[#0c0c0c] bg-opacity-60 px-[20px] mb-[5px] border-l-2 border-green-500 pb-3 rounded-b-lg">
              <div
                onClick={searchNftData}
                className="flex items-center justify-center bg-green-500 rounded-b-lg font-bold hover:bg-green-500 hover:bg-opacity-80 px-[20px] py-3 cursor-pointer"
              >
                <p className="mr-2">Check what's in your NFT </p>
                <img
                  src="images/traced-fomo-left.png "
                  className="w-5"
                  alt="omo-left"
                />
                <p>🎁👉</p>
              </div>
            </div>

            <div className="text-[25px] text-green-500 font-bold py-[20px] text-center ">
              STAKING
            </div>

            <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] rounded-t-lg my-[5px] border-l-2 border-green-500">
              <div className="flex py-[15px]">
                <div className="w-1/2 text-left">Earn:</div>
                <div className="w-1/2 text-right">FOMO/Airdrops</div>
              </div>
            </div>

            <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] my-[5px] border-l-2 border-green-500">
              <div className="flex py-[15px]">
                <div className="w-1/2 text-left">Your Staked FOMO:</div>
                <div className="w-1/2 flex items-center justify-end">
                  <Tooltip
                    content={userStakedFomo}
                    className="bg-[#232325] bg-opacity-90 backdrop-blur-lg"
                    arrow={false}
                  >
                    {formatTokenAmount(userStakedFomo)}
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] my-[5px] border-l-2 py-2 border-green-500">
              <div className="flex text-left">
                <div className="w-2/3">
                  <select
                    className="bg-[#232325] bg-opacity-60 p-[10px]  outline-none w-full rounded-l-lg"
                    value={selectedNftID}
                    onChange={handleSelectChange}
                  >
                    <option value="">Select a NFT </option>
                    {userFomoNfts.map((option) => (
                      <option
                        key={option.non_fungible_id}
                        value={option.non_fungible_id}
                      >
                        {option.non_fungible_id} :{" "}
                        {option.data.programmatic_json.fields[2].value} FOMO
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-1/3">
                  <div
                    onClick={() => unStake(selectedNftID, walletAddress)}
                    className=" bg-green-500 p-[10px] w-full  text-center font-bold rounded-r-lg hover:cursor-pointer hover:bg-opacity-80"
                  >
                    UNSTAKE
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] my-[5px] py-[15px] rounded-b-lg border-l-2 border-green-500">
              <div className="flex text-[12px]">
                <div className="text-gray-400">Balance:&nbsp;</div>
                <Tooltip
                  content={fomoBalance}
                  className="bg-[#232325] bg-opacity-90 backdrop-blur-lg"
                  arrow={false}
                >
                  <div className="cursor-pointer">
                    {formatTokenAmount(+fomoBalance)}
                  </div>
                </Tooltip>
                <div className="text-gray-400">&nbsp; FOMO</div>
              </div>
              <div className="relative">
                <input
                  type="number"
                  placeholder="Input Amount"
                  min={1}
                  value={inputStakingAmount}
                  onChange={(e) => setInputAmount(parseInt(e.target.value))}
                  className="bg-[#232325] bg-opacity-60 p-[10px] my-[5px] outline-none w-full rounded-t-lg"
                ></input>
                <div
                  onClick={setMaxStakingAmount}
                  className="absolute right-[35px] top-[15px] hover:cursor-pointer"
                >
                  MAX
                </div>
              </div>
              <div className="flex text-[12px] items-end">
                <div className="text-gray-400">You are staking &nbsp;</div>
                <div>{formatTokenAmount(inputStakingAmount)}</div>
                <div className="text-gray-400">&nbsp; FOMO</div>
              </div>
              <div
                onClick={() => Stake(inputStakingAmount, walletAddress)}
                className="bg-green-500 p-[10px] text-center my-[5px] rounded-b-lg font-bold hover:cursor-pointer hover:bg-opacity-80"
              >
                STAKE
              </div>
              <div className="flex items-center justify-center mt-2">
                <img src="images/icon/info.svg" className="w-5" alt="info" />
                <p className="text-sm ml-1 ">
                  The more you stake, the higher your share of FOMO
                  rewards/airdrops
                </p>
              </div>
            </div>
            <img
              src="images/traced-fomo-left.png "
              className="absolute left-[0px] top-[-70px] lg:w-[150px] w-[100px] floating"
              alt="omo-left"
            />
            <img
              className="absolute right-[0px] top-[-70px]  lg:w-[150px] w-[100px] floating"
              src="/images/traced-fomo-right.png"
              width={100}
              alt="omo-right"
            />
          </div>
        </div>
        {/* <div className="mx-auto bg-[#232325] bg-opacity-10 backdrop-blur-lg lg:min-w-[500px] min-w-[320px] p-[20px] rounded-lg relative border-[1px] border-gray-600">
          <iframe
            title="swap-widget"
            src="https://ociswap.com/widgets/swap?from=resource_rdx1t4a42s786rs0nz6lv3skg2kvk94alxrpd5v73q9e7jrt33fsalcg0m&to=resource_rdx1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxradxrd&theme=dark"
            scrolling="no"
            width="100%"
            height="550px"
            style={{
              overflow: "hidden",
              border: "none",
              minWidth: "250px",
              maxWidth: "960px",
            }}
          />
        </div> */}
      </div>
      <AirdropConfirmModal
        isModalOpen={isConfirmAirdropOpen}
        onAccept={() => {
          airdrop(
            inputAirdropAmount,
            selectedTokenIDToAirdrop.split(",")[0],
            walletAddress
          );
          setIsConfirmAirdropOpen(false);
        }}
        onClose={() => setIsConfirmAirdropOpen(false)}
        amount={inputAirdropAmount}
        tokenSymbol={selectedTokenIDToAirdrop.split(",")[1]}
      />
    </>
  );
};
