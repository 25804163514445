export const RESOURCE_ADDRESS_TO_STAKE =
  "resource_rdx1t4a42s786rs0nz6lv3skg2kvk94alxrpd5v73q9e7jrt33fsalcg0m";
export const OWNER_BADGE =
  "resource_rdx1t5a4neyeghh5tl8ucnn7nftwa6r0094g56hxq58m5ycufl6ghr2fa7";
export const STAKE_COMPONENT_ADDRESS =
  "component_rdx1czhz5y9eucherc7gvwhsjvdu6yeywh9pdtrulu5n76mt0x8llf536e";
export const ACCOUNT_HOLDING_OWNER_BADGE =
  "account_rdx128wxqwpqn0mtdc6dtyaf4sgkqtluxpc09rue7hu5eczvxsuzqc3dxx";
export const STAKED_TOKEN_ADDRESS =
  "resource_rdx1nf4djwvfsel2axsggh6k6x8a2cuycayn08c97z5u34uuegtdan5d7z";
export const DAPP_DEFINITION_ADDRESS =
  "account_rdx1288ae9j55j9hu3jamwd04w3hyj7tjyj9tle7jz5my8ntvsrw7jyj7k";
export const FOMO_AIRDROPS_VAULT_ADDRESS =
  "internal_keyvaluestore_rdx1krtdfzq6lq0eqfjdsessnlys9p4f05qy6vhx5dzjxkx3d5uuyw7jeg";
